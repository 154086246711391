import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Row, Col, Image } from "react-bootstrap"

// import Detimage from "./../components/detailImage"
import aboutimage from "./../images/about.jpg"
import "./../components/layout.css"

const AboutPage = () => (
    <Layout>
        <Seo title="Manon Lambeens, About, Henri Vandevelde" />

        <Row className="text-start pl-0 pr-0">
            <Col xxl={4} xl={4} lg={4} md={4} s={4} xs={12} className="pe-1 ps-1 mt-2">
                <Image src={aboutimage} alt="Manon Lambeens" fluid />
            </Col>
            <Col xxl={8} xl={8} lg={8} md={8} s={8} xs={12} className="pe-1 ps-1 mt-2">
                <p className="textAbout">Manon Lambeens ('98) is a graphic/social designer and an illustrator based in Leuven, Belgium. She graduated from the Royal Academy for Fine Arts in Ghent
                    (KASK) in a master graphic design. Currently she is studying an educative master at the Royal Academy for Fine Arts in Antwerp.
                    During her studies Manon went on Erasmus to the HFBK. At the HFBK she designed her first Typeface Borgfeld.
                </p>
                
                <p>
                    <b className="text-start text-uppercase">Awards and prices</b>
                    <ul className="aboutLists">
                        <li className="mt-2">
                            2022 Henry Van de Velde public, gold award.
                        </li>
                        <li className="mt-2">
                            2022 Henry Van de Velde space, bronze award.
                        </li>
                        <li className="mt-2">
                            2021 Arts thread global graduation show in collaboration with Gucci.
                        </li>
                        <li className="mt-2">
                            2021 Nominatie stichting Bruynseraede, KASK.
                        </li>
                        <li className="mt-2">
                            2021 We are the next generation, Kortrijk.
                        </li>
                        <li className="mt-2">
                            2021 Flanders DC 25 pas afgestudeerde designers om in het oog te houden.
                        </li>
                    </ul>
                </p>
                <p>
                    <b className="text-start text-uppercase">Education</b>
                    <ul className="aboutLists">
                    <li className="mt-2">
                            2021 Summerschool Kortrijk Design regio, Living waterscapes.
                        </li>
                        <li className="mt-2">
                            2019 - 2020 Graphic design and typografie at the HFBK, Hamburg.
                        </li>
                        <li className="mt-2">
                            2016 - 2021 Master degree graphic design at KASK School of Arts, Ghent.
                        </li>
                    </ul>
                </p>
            </Col>
        </Row>
    </Layout>
)

export default AboutPage;